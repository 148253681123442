<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <div class="demo-inline-spacing">
        <b-button variant="gradient-primary" @click="modalTerbitkan()">
          <feather-icon icon="ExternalLinkIcon" class="mr-50" />
          <span class="align-middle">Terbitkan</span>
        </b-button>
        <b-button variant="gradient-primary" @click="modalUndanganKrip()">
          <feather-icon icon="CalendarIcon" class="mr-50" />
          <span class="align-middle">Undang KRIP</span>
        </b-button>
      </div>
    </b-card-body>

    <!-- list data calon angggota-->
    <template #modal-title>Detail Data </template>
    <!-- Invoice Description: Total -->
    <table style="width: 100%">
      <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="7" class="text-right">
              #{{ detailRekomPraktik.id }} [{{
                detailRekomPraktik.reksip_kategori
              }}] [<b-badge
                variant="success"
                v-if="detailRekomPraktik.reksip_terbit != false"
                >{{ "Sudah Terbit" }}</b-badge
              ><b-badge variant="danger" v-else>{{ "Belum Terbit" }}</b-badge
              >]
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th rowspan="30">
              Data Rekom
            </b-th>
            <b-th class="text-right">
              Anggota :
            </b-th>
            <b-td
              ><a
                :href="`/anggota/${detailRekomPraktik.orang_id}`"
                target="_blank"
                >{{ detailRekomPraktik.orang_id }}</a
              ></b-td
            >
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Invoice :
            </b-th>
            <b-td
              ><a
                :href="`/invoice/${detailRekomPraktik.invoice_id}`"
                target="_blank"
                >{{ detailRekomPraktik.invoice_id }}</a
              >
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip Kategori :
            </b-th>
            <b-td> {{ detailRekomPraktik.orang_npa_masa_berlaku }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip Pendidikan File :
            </b-th>
            <b-td v-if="detailRekomPraktik.reksip_pend_file != null">
              <a
                v-if="!detailRekomPraktik.reksip_pend_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_pend_file/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_pend_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Pendidikan</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_pend_file"
                alt="pdf"
                target="_blank"
                >Lihat File Pendidikan</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip STR File :
            </b-th>
            <b-td v-if="detailRekomPraktik.reksip_str_file != null">
              <a
                v-if="!detailRekomPraktik.reksip_str_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_str_file/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_str_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Str</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_str_file"
                alt="pdf"
                target="_blank"
                >Lihat File Str</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip Keterangan kerja :
            </b-th>
            <b-td
              v-if="detailRekomPraktik.reksip_file_keterangan_kerja != null"
            >
              <a
                v-if="
                  !detailRekomPraktik.reksip_file_keterangan_kerja.includes(
                    'https'
                  )
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_file_keterangan_kerja/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_file_keterangan_kerja
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Str</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_file_keterangan_kerja"
                alt="pdf"
                target="_blank"
                >Lihat File Keterangan Kerja</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip Keterangan perhimpunan :
            </b-th>
            <b-td
              v-if="
                detailRekomPraktik.reksip_file_keterangan_perhimpunan != null
              "
            >
              <a
                v-if="
                  !detailRekomPraktik.reksip_file_keterangan_perhimpunan.includes(
                    'https'
                  )
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_file_keterangan_perhimpunan/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_file_keterangan_perhimpunan
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Str</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_file_keterangan_perhimpunan"
                alt="pdf"
                target="_blank"
                >Lihat File Keterangan Perhimpunan</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip Cabang Untuk Non Jakpus :
            </b-th>
            <b-td
              v-if="
                detailRekomPraktik.reksip_file_rekomcabang_untuk_nonjakpus !=
                  null
              "
            >
              <a
                v-if="
                  !detailRekomPraktik.reksip_file_rekomcabang_untuk_nonjakpus.includes(
                    'https'
                  )
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_file_rekomcabang_untuk_nonjakpus/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_file_rekomcabang_untuk_nonjakpus
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Str</a
              >
              <a
                v-else
                :href="
                  detailRekomPraktik.reksip_file_rekomcabang_untuk_nonjakpus
                "
                alt="pdf"
                target="_blank"
                >Lihat Rekomcabang Untuk Non Jakpus</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip File Surat Idi Wilayah :
            </b-th>
            <b-td
              v-if="detailRekomPraktik.reksip_file_surat_idi_wilayah != null"
            >
              <a
                v-if="
                  !detailRekomPraktik.reksip_file_surat_idi_wilayah.includes(
                    'https'
                  )
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_file_surat_idi_wilayah/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_file_surat_idi_wilayah
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File Str</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_file_surat_idi_wilayah"
                alt="pdf"
                target="_blank"
                >Lihat File Surat Idi Wilayah</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip NPA File :
            </b-th>
            <b-td v-if="detailRekomPraktik.reksip_npa_file != null">
              <a
                v-if="!detailRekomPraktik.reksip_npa_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_npa_file/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_npa_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File NPA</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_npa_file"
                alt="pdf"
                target="_blank"
                >Lihat File NPA</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Reksip KRIP File :
            </b-th>
            <b-td v-if="detailRekomPraktik.reksip_krip_file != null">
              <a
                v-if="!detailRekomPraktik.reksip_krip_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_krip_file/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_krip_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File KRIP</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_krip_file"
                alt="pdf"
                target="_blank"
                >Lihat File KRIP</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom Tidak Kena Sanksi :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_tidak_kena_sanksi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Dibuat :
            </b-th>
            <b-td> {{ detailRekomPraktik.created_at }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Diupdate :
            </b-th>
            <b-td> {{ detailRekomPraktik.updated_at }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Nama Instansi :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_nama_instansi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom Sah Etika :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_sah_etika }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom Sah Fisikmental :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_sah_fisikmental }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom sah kompetensi :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_sah_kompetensi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom sah ketua idi cabang :
            </b-th>
            <b-td v-if="detailRekomPraktik.reksip_sah_ketua_idi_cab != null">
              <a
                v-if="
                  !detailRekomPraktik.reksip_sah_ketua_idi_cab.includes('https')
                "
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/reksip_krip_file/' +
                    detailRekomPraktik.id +
                    '/' +
                    detailRekomPraktik.reksip_sah_ketua_idi_cab
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File KRIP</a
              >
              <a
                v-else
                :href="detailRekomPraktik.reksip_sah_ketua_idi_cab"
                alt="pdf"
                target="_blank"
                >Lihat File SIP yang telah sah di terbitkan</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Status Pernikahan :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_sah_ketua_idi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom ke :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_rekom_ke }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom Terbit :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_terbit }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom alamat instansi :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_alamat_instansi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom kompetensi nomor :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_kompetensi_no }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom kompetensi jenis :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_kompetensi_jenis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom kompetensi file :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_kompetensi_file }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom str nomor :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_str_no }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Rekom str tanggal berakhir :
            </b-th>
            <b-td> {{ detailRekomPraktik.reksip_str_tgl_berakhir }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </table>

    <!-- add undangan krip-->
    <b-modal
      id="undangan-krip"
      title="Jadwal Krip"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group>
          <v-select
            v-model="kripjadwal_tanggal"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="tanggal"
            placeholder="pilih tanggal..."
            :options="option"
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="undangKRIP"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-terbitkan-sip"
      title="Terbitkan SIP"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeTerbitkanSIP">
        <b-form @submit.prevent>
          <b-form-group label="File Sah SIP" label-for="file_sah_sip ">
            <validation-provider
              #default="{ errors }"
              name="File Sah SIP"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file_sah_sip"
                  multiple
                  v-model="uploadSipSah"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerUploadSipSah($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeTerbitkanSIP"
          >
            Terbitkan/uploadFile
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat jadwal
      jadwalCurrentPage: 1,
      jadwalRows: 0,
      jadwalPerPage: 20,
      modes: ["multi", "single", "range"],
      search: null,

      detailRekomPraktik: {},

      sendemail: {
        subject: "",
        message: "",
      },
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      tanggals: [], //map tanggal krip
      option: [], // store tanggal with option select
      uploadSipSah: "",
      solidColor: [{ bg: "danger", title: "Danger card title" }],

      snowOptions: {
        theme: "snow",
      },
    };
  },

  computed: {},
  methods: {
    validateBeforeTerbitkanSIP() {
      this.$refs.validateBeforeTerbitkanSIP.validate().then((success) => {
        if (success) {
          this.terbitkanSIP();
        }
      });
    },
    handlerUploadSipSah(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerUploadSipSah(files[0], (result) => {
          this.uploadSipSah = result;
        });
      }
    },
    createHandlerUploadSipSah(file, cb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getDetailRekomPraktik();
        this.tanggal_krip();
      } else {
        this.currentPage = 1;
      }
    },
    modalUndanganKrip() {
      this.tanggal_krip();
      this.loopTanggal();
      this.$bvModal.show("undangan-krip");
    },

    modalTerbitkan() {
      this.$bvModal.show("modal-terbitkan-sip");
    },

    async terbitkanSIP() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data Rekom ini akan diterbitkan, Apakah Anda Ingin Melanjutkan ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Terbitkan!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((data) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (data.value) {
              return API.rekomendasi_izin_praktik.terbitkan(
                this.detailRekomPraktik.id,
                { reksip_sah_ketua_idi_cab: this.uploadSipSah }
              );
            }
            return false;
          })
          .then((data) => {
            if (data) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Terbitkan rekom praktik",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
            this.$bvModal.hide("modal-terbitkan-sip");
          })
          .catch((error) => {
            this.handleMsgErrors(error, "gagal di terbitkan");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async loopTanggal() {
      await this.tanggals.map((tanggals) =>
        this.option.push(tanggals.kripjadwal_tanggal)
      );
    },
    async tanggal_krip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        const form = {
          limit: this.jadwalPerPage,
          page: this.jadwalCurrentPage,
          search: this.search,
        };
        const { data } = await API.jadwal_krip.list(form);
        this.tanggals = data.data;
        this.handleMsgSuccess("berhasil menampilkan jadwal krip");
      } catch (error) {
        this.handleMsgErrors(error, "error sistem");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async undangKRIP() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = this.detailRekomPraktik.id; // id rekom
        let body = {
          kripjadwal_id: this.kripjadwal_tanggal,
        };
        await API.rekomendasi_izin_praktik.undangan(id, body);
        this.handleMsgSuccess("Berhasil tambah undangan Krip");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal add undangan krip");
      } finally {
        this.$bvModal.hide("undangan-krip");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async getDetailRekomPraktik() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.rekomendasi_izin_praktik.detail(
          this.$route.params.invoices_id //fetch data invoice by id(sesuai dengan id invoice)
        );
        this.detailRekomPraktik = data; //simpan detail data invoice in property

        this.handleMsgSuccess("Berhasil Menampilkan Detail Rekom Praktik");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },

  mounted() {
    this.getDetailRekomPraktik();
    this.tanggal_krip();
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
